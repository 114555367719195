import React, { FC } from 'react';
import styled from 'styled-components';

import NumberInCircle from 'components/NumberInCircle';
import Heading, { HeadingScope, HeadingVariant } from 'components/Heading';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div<{ $largeIcon: boolean }>`
  margin-right: 6px;
  height: ${({ $largeIcon }) => ($largeIcon ? '80px' : '40px')};
  text-align: center;
  width: ${({ $largeIcon }) => ($largeIcon ? '80px' : '40px')};
`;

const Title = styled(Heading)`
  margin: 24px 0;
`;

const Text = styled.p`
  margin: 0;
`;

interface InfoCardProps {
  icon?: React.ReactNode;
  number?: string;
  text: string;
  title: string;
  largeIcon?: boolean;
}

const InfoCard: FC<InfoCardProps> = ({
  icon,
  number,
  text,
  title,
  largeIcon = false,
}) => (
  <Container>
    {icon && <Icon $largeIcon={largeIcon}>{icon}</Icon>}
    {number && <NumberInCircle number={number} small={false} />}
    <HeadingScope>
      <Title variant={HeadingVariant.CHARLIE}>{title}</Title>
    </HeadingScope>
    <Text>{text}</Text>
  </Container>
);

export default InfoCard;
