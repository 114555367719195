import React, { FC } from 'react';
import styled from 'styled-components';

import Heading, { HeadingScope, HeadingVariant } from 'src/components/Heading';
import { device } from 'src/theme/breakpoints';
import Link, { LinkVariant } from 'components/Link';

const Row = styled.div<{ $imageOnLeft: boolean; $smallGap: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${({ $imageOnLeft }) =>
    $imageOnLeft ? 'row-reverse' : 'row'};
  justify-content: center;
  column-gap: ${({ $smallGap }) => ($smallGap ? '32px' : '80px')};

  @media ${device.mobileDOWN} {
    flex-direction: column-reverse;
  }

  @media ${device.laptopUP} {
    column-gap: ${({ $smallGap }) => ($smallGap ? '32px' : '160px')};
  }
`;

const Content = styled('div')`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media ${device.tabletUP} {
    flex: 0 1 578px;
  }
`;

const Text = styled.p`
  margin: 24px 0 32px;

  @media ${device.mobileDOWN} {
    margin: 16px 0 24px;
  }
`;

const ImageWrapper = styled.div`
  @media ${device.mobileDOWN} {
    max-width: 196px;
  }
`;

interface ImageRowProps {
  children: React.ReactNode;
  heading?: string;
  image: React.ReactNode;
  imageOnLeft?: boolean;
  linkText?: string;
  linkUrl?: string;
  smallGap?: boolean;
}

const ImageRow: FC<ImageRowProps> = ({
  children,
  heading,
  image,
  imageOnLeft = false,
  linkText,
  linkUrl,
  smallGap = false,
}) => (
  <Row $imageOnLeft={imageOnLeft} $smallGap={smallGap}>
    <Content>
      {heading && (
        <HeadingScope>
          <Heading variant={HeadingVariant.CHARLIE}>{heading}</Heading>
        </HeadingScope>
      )}
      <Text>{children}</Text>
      {linkText && linkUrl && (
        <Link url={linkUrl} variant={LinkVariant.ALFA}>
          {linkText}
        </Link>
      )}
    </Content>
    <ImageWrapper>{image}</ImageWrapper>
  </Row>
);

export default ImageRow;
