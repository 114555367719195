import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import Content from 'src/components/Content';
import { device } from 'src/theme/breakpoints';

const Container = styled.div<{ $isDarker: boolean }>`
  background-color: ${({ $isDarker }) =>
    $isDarker ? `${colors.grey200}` : `${colors.grey100}`};
  margin-top: 48px;
  padding-bottom: 40px;

  @media ${device.laptopSUP} {
    margin-top: 80px;
    padding-bottom: 80px;
  }
`;

interface BackgroundSectionProps {
  children: React.ReactNode;
  isDarker?: boolean;
}

const BackgroundSection: FC<BackgroundSectionProps> = ({
  children,
  isDarker = false,
}) => (
  <Container $isDarker={isDarker}>
    <Content>{children}</Content>
  </Container>
);

export default BackgroundSection;
