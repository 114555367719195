import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import { ConnectIcon, RewindIcon, TrustIcon } from 'src/assets/icons';
import Button, { ButtonVariant } from 'components/Button';
import { useModal } from 'components/Modal/ModalContext';

export const getOPCPageOverviewData = () => {
  const { t } = useTranslation();

  return [
    {
      image: (
        <StaticImage
          src="../../assets/images/features/organised-product-content/opc-photos.jpg"
          alt={t('opc_overview_1_title')}
          width={280}
          height={220}
          quality={100}
          placeholder="blurred"
        />
      ),
      heading: t('opc_overview_1_title'),
      children: <Trans i18nKey="opc_overview_1_text" />,
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/features/organised-product-content/opc-sheets.jpg"
          alt={t('opc_overview_2_title')}
          width={290}
          height={220}
          quality={100}
          placeholder="blurred"
        />
      ),
      heading: t('opc_overview_2_title'),
      children: <Trans i18nKey="opc_overview_2_text" />,
    },
  ];
};

export const getOPCBenefitsData = () => {
  const { t } = useTranslation();

  return [
    {
      image: <RewindIcon />,
      title: t('opc_benefits_1_title'),
      children: t('opc_benefits_1_text'),
    },
    {
      image: <TrustIcon />,
      title: t('opc_benefits_2_title'),
      children: t('opc_benefits_2_text'),
    },
    {
      image: <ConnectIcon />,
      title: t('opc_benefits_3_title'),
      children: t('opc_benefits_3_text'),
    },
  ];
};

export const getOPCImpactData = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return [
    {
      image: (
        <StaticImage
          src="../../assets/images/features/organised-product-content/opc-product-cards.png"
          alt={t('opc_impact_1_title')}
          width={290}
          height={290}
          quality={100}
          placeholder="blurred"
        />
      ),
      heading: t('opc_impact_1_title'),
      children: <Trans i18nKey="opc_impact_1_text" />,
      imageOnLeft: true,
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/features/organised-product-content/opc-multilanguage.png"
          alt={t('opc_impact_2_title')}
          width={290}
          height={290}
          quality={100}
          placeholder="blurred"
        />
      ),
      heading: t('opc_impact_2_title'),
      children: (
        <>
          <Trans i18nKey="opc_impact_2_text_0" />
          <Button variant={ButtonVariant.CHARLIE} onClick={openModal}>
            <Trans i18nKey="opc_impact_2_text_1" />
          </Button>
          <Trans i18nKey="opc_impact_2_text_2" />
        </>
      ),
      imageOnLeft: false,
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/features/organised-product-content/opc-data-feed.png"
          alt={t('opc_impact_3_title')}
          width={290}
          height={290}
          quality={100}
          placeholder="blurred"
        />
      ),
      heading: t('opc_impact_3_title'),
      children: <Trans i18nKey="opc_impact_3_text" />,
      imageOnLeft: true,
    },
  ];
};
