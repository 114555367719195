import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Content from 'components/Content';
import SectionTitle from 'components/SectionTitle';
import { device } from 'src/theme/breakpoints';
import Grid from 'components/Grid';
import ImageRow from 'components/ImageRow';
import {
  getOPCBenefitsData,
  getOPCImpactData,
  getOPCPageOverviewData,
} from 'src/data/organised-product-content/data';
import BackgroundSection from 'components/BackgroundSection';
import InfoCard from 'components/InfoCard';
import { HeadingScope } from 'components/Heading';
import ContactForm from 'components/ContactForm';
import { Hero } from './product-information-import';

const OverviewSection = styled(Grid)`
  @media ${device.laptopUP} {
    column-gap: 104px;
  }
`;

const ImpactSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 88px;

  @media ${device.mobileDOWN} {
    row-gap: 32px;
  }
`;

const OPCPage: FC = () => {
  const { t } = useTranslation();
  const overviewData = getOPCPageOverviewData();
  const benefitsData = getOPCBenefitsData();
  const impactData = getOPCImpactData();

  return (
    <Layout footerHasMargin>
      <Hero
        image={
          <StaticImage
            src="../assets/images/features/organised-product-content/opc-main.jpg"
            alt={t('pii_heading')}
            layout="fullWidth"
          />
        }
        title={t('opc_heading')}
        text={t('opc_text')}
      />
      <HeadingScope>
        <Content>
          <SectionTitle>
            <Trans i18nKey="opc_overview_title" />
          </SectionTitle>
          <OverviewSection columns={2}>
            {overviewData.map(data => (
              <ImageRow
                key={data.heading}
                heading={data.heading}
                image={data.image}
                smallGap
              >
                {data.children}
              </ImageRow>
            ))}
          </OverviewSection>
        </Content>
        <BackgroundSection>
          <SectionTitle subtitle={t('opc_benefits_subtitle')}>
            <Trans i18nKey="opc_benefits_title" />
          </SectionTitle>
          <Grid columns={3}>
            {benefitsData.map(benefit => (
              <InfoCard
                key={benefit.title}
                icon={benefit.image}
                title={benefit.title}
                text={benefit.children}
                largeIcon
              />
            ))}
          </Grid>
        </BackgroundSection>
        <Content>
          <SectionTitle subtitle={t('opc_impact_subtitle')} centered>
            <Trans i18nKey="opc_impact_title" />
          </SectionTitle>
          <ImpactSection>
            {impactData.map(feature => (
              <ImageRow
                heading={feature.heading}
                image={feature.image}
                key={feature.heading}
                imageOnLeft={feature.imageOnLeft}
              >
                {feature.children}
              </ImageRow>
            ))}
          </ImpactSection>
        </Content>
      </HeadingScope>
      <ContactForm />
    </Layout>
  );
};

export default OPCPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Organize Product Content | OmniGet"
    description="Keep your product content organized and accessible with OmniGet. Simplify content management to boost efficiency and improve collaboration."
  />
);
